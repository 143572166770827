/*
 * @Author: swcbo
 * @Date: 2021-09-16 00:12:05
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-29 08:54:18
 * @FilePath: /zeekrlife/src/App.tsx
 * @Description:
 */
import RouteRender from '@/routers/RouteRender';
import history from '@/utils/history';
import { useLayoutEffect } from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { base } from './api/route';
import { getAuth, getCode, setAuth } from './utils';

const App = () => {
  useLayoutEffect(() => {
    const code = getCode();
    setAuth('');
    if (window.location.href.indexOf('3100') !== -1) {
      return;
    }
    if (!code) {
      if (!['/', '/over'].includes(window.location.pathname)) {
        const redirectUrl = encodeURIComponent(
          'https://zeekr.zhongdhy.top/home',
        );
        const appid = 'wx9b26282a1d8e494b'; // 正式
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appid +
          '&redirect_uri=' +
          redirectUrl +
          '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
      }

      return;
    }
    // const doConfig = async () => {
    //   const data = await getWxConfig(window.location.href);
    //   wx.config({
    //     debug: false,
    //     ...data,
    //     jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
    //   });
    //   const wxParam = {
    //     title: 'ZEEKR',
    //     link: 'https://zeekr.zhongdhy.top/home',
    //     imgUrl: 'https://www.zeekrlife.com/favicon.png',
    //     desc: `我的的分享`,
    //   };
    //   wx.ready(() => {
    //     wx.onMenuShareTimeline(wxParam);
    //     wx.onMenuShareAppMessage(wxParam);
    //   });
    // };
    if (!getAuth() && code) {
      base(code)
        .then(({ id }) => {
          setAuth(id);
          // doConfig();
        })
        .catch(() => {
          history.push('/home');
        });
    } else {
      // if (getAuth()) {
      //   doConfig();
      // }
    }
  }, []);
  return (
    <>
      <Router history={history}>
        <RouteRender />
      </Router>
    </>
  );
};
export default App;
