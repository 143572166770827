/* eslint-disable no-undef */
/*
 * @Descripttion: 工具类
 * @version:
 * @Author: 小白
 * @Date: 2020-10-04 13:11:40
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-22 16:42:49
 */
import Token from 'qiniu-token';
import * as qiniu from 'qiniu-js';
const myToken = new Token({
  bucket: 'gavment',
  accessKey: 'hg4vAfkdCV722cYH9XNUInOTs2Un0cG7pcXzNLAl',
  secretKey: '21O8OX68FRprEXvMBLw2Jcuhg8VnAf9nplJHMg7d',
});
export const uploadFile = (
  file: File,
  token = myToken.getToken(),
  qiniuDomain = 'https://cdng.huangb.top/',
) =>
  new Promise((resolve, reject) => {
    const key = `zeekr/store/${
      new Date().getTime().toString() + '.' + file.name.split('.')[1]
    }`;
    // let that = this //
    const observer = {
      next(res: any) {
        console.log(res);
      },
      error(err: any) {
        reject(err);
      },
      complete(res: any) {
        resolve(qiniuDomain + key);
      },
    };
    qiniu
      .upload(
        file,
        key,
        token,
        {
          fname: file.name,
        },
        {
          useCdnDomain: true,
        },
      )
      .subscribe(observer);
  });

export const setAuth = (auth: string) => {
  window.localStorage.setItem('auth', auth);
};

export const getAuth = () => {
  const auth = window.localStorage.getItem('auth');
  return auth || '';
};
export const getCode = () => {
  return window.location.search
    ? searchObj(window.location.search).code
    : window.location.pathname.split('/')[2];
};
export const getUrlParams = (key: string) => {
  const params = new URL(window.location.href).searchParams;
  return params.get(key) || null;
};

/**
 * 获取url参数
 * @param search url参数
 */
export const searchObj = (search: string) => {
  const body = JSON.parse(
    '{"'.concat(
      decodeURIComponent(search.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"'),
      '"}',
    ),
  );
  return body;
};

export const treeToList = (list: any[], parents: string | string[]) => {
  let dataList: any[] = [];
  list.forEach((v) => {
    if (typeof parents === 'string') {
      if (v[parents]) {
        dataList = [...dataList, ...treeToList(v[parents], parents)];
      } else {
        dataList.push(v);
      }
    } else {
      let isHave = false;
      parents.forEach((parent) => {
        if (v[parent]) {
          dataList = [...dataList, ...treeToList(v[parent], parents)];
          isHave = true;
        }
      });
      if (!isHave) {
        dataList.push(v);
      }
    }
  });
  return dataList;
};

export const setWindowHeight = () => {
  const windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowHeight = document.documentElement.clientHeight;
    } else {
      // @ts-ignore
      windowHeight = window.body.clientHeight;
    }
  }
  document
    .getElementsByTagName('body')[0]
    .style.setProperty('--height-primary', `${windowHeight}px`);
};

export const getPoint = (before?: number, after?: number) => {
  let point = 0;
  if (before) {
    if (before < 7 && before > 0) {
      point = 1;
    } else if (before < 13 && before > 6) {
      point = 2;
    } else if (before < 19 && before > 12) {
      point = 3;
    } else if (before < 25 && before > 18) {
      point = 4;
    } else if (before < 31 && before > 24) {
      point = 5;
    } else if (before < 37 && before > 30) {
      point = 6;
    } else if (before < 43 && before > 36) {
      point = 7;
    } else if (before < 49 && before > 42) {
      point = 8;
    } else if (before < 55 && before > 48) {
      point = 9;
    } else if (before < 61 && before > 54) {
      point = 10;
    } else if (before < 67 && before > 60) {
      point = 11;
    } else if (before < 73 && before > 66) {
      point = 12;
    } else if (before > 72) {
      point = 13;
    }
  }
  if (after) {
    if (after < 2 && after > 0) {
      point += 1;
    } else if (after < 5 && after > 2) {
      point += 2;
    } else if (after < 7 && after > 4) {
      point += 3;
    } else if (after > 6) {
      point += 4;
    }
  }
  return point;
};
