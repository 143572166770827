/*
 * @Descripttion:
 * @version:
 * @Author: 小白
 * @Date: 2020-10-05 22:06:34
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-26 21:45:15
 */
import { White } from '@/typings';
import { lazy } from 'react';
const Calculator = lazy(
  () => import(/* chunkName: "Calculator" */ '@/pages/calculator'),
);
const Vip = lazy(() => import(/* chunkName: "Vip" */ '@/pages/vip'));
const Error = lazy(() => import(/* chunkName: "Error" */ '@/pages/error'));
const Success = lazy(
  () => import(/* chunkName: "Success" */ '@/pages/success'),
);
const Home = lazy(() => import(/* chunkName: "Home" */ '@/pages/home'));
const SVip = lazy(() => import(/* chunkName: "SVip" */ '@/pages/sVip'));
const Over = lazy(() => import(/* chunkName: "Over" */ '@/pages/over'));
const Fail = lazy(() => import(/* chunkName: "Over" */ '@/pages/fail'));
const Drive = lazy(() => import(/* chunkName: "drive" */ '@/pages/drive'));
const Next = lazy(() => import(/* chunkName: "Next" */ '@/pages/next'));
const Register = lazy(
  () => import(/* chunkName: "Register" */ '@/pages/register'),
);
const Punch = lazy(() => import(/* chunkName: "Punch" */ '@/pages/punch'));
const Share = lazy(() => import(/* chunkName: "Share" */ '@/pages/share'));
const NoFound = lazy(
  () => import(/* chunkName: NoFound */ '../components/NoFound'),
);
export const TabBarList: White.RouteTabBar[] = [];

const routes: White.RouteConfig[] = [
  {
    path: '/',
    component: Calculator,
    title: '北京市小客车指标家庭积分计算器',
  },
  {
    path: '/home',
    component: Home,
    title: 'ZEEKR',
  },
  {
    path: '/next',
    component: Next,
    title: 'ZEEKR',
  },
  {
    path: '/sVip',
    component: SVip,
    title: 'ZEEKR',
  },
  {
    path: '/error',
    component: Error,
    title: 'ZEEKR',
  },
  {
    path: '/register',
    component: Register,
    title: 'ZEEKR',
  },
  {
    path: '/drive',
    component: Drive,
    title: 'ZEEKR',
  },
  {
    path: '/share',
    component: Share,
    title: 'ZEEKR',
  },
  {
    path: '/success',
    component: Success,
    title: 'ZEEKR',
  },
  {
    path: '/punch',
    component: Punch,
    title: 'ZEEKR',
  },
  {
    path: '/vip',
    component: Vip,
    title: 'ZEEKR',
  },
  {
    path: '/over',
    component: Over,
    title: '北京市小客车指标家庭积分计算器',
  },
  {
    path: '/fail',
    component: Fail,
    title: '北京市小客车指标家庭积分计算器',
  },
  {
    path: '*',
    component: NoFound,
  },
];

export default [...routes];
